import React, { useEffect, useMemo, useState } from "react";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import { Logo } from "studio-design-system";
import { useWindowSize } from "usehooks-ts";

import { createProductDraft, isCreatorWithDrafts } from "~/api/creatorApi";
import { BurgerMenu } from "~/components/music/icons/burger-menu";
import { AuthModalInteractionSource } from "~/hooks/auth/AuthProvider";
import { useAllAuthModalFunctions } from "~/hooks/auth/useAuthModal";
import { useCurrentSession } from "~/hooks/currentSessionProvider/useCurrentSession";
import { useSchoolCoreData } from "~/hooks/schoolCoreProvider/useSchoolCoreProvider";
import { useIntercomFeature } from "~/hooks/support/useIntercomFeature";

import { useAuth, useSignOut } from "../../../hooks/auth/useAuth";
import { AuthForm } from "../../auth/authTypes";
import { CartPill } from "../CartPill";
import { SearchIcon } from "../Icons/SearchIcon";
import { MarketingHeader } from "../MarketingHeader";
import { SearchModal } from "../Modals/SearchModal";
import { OverlayModal } from "../OverlayModal";
import * as styles from "./MarketingHeaderWithAuth.css";

const MobileNavigation = dynamic(() => import("./MobileNavigation"));
const DesktopNavigation = dynamic(() => import("./DesktopNavigation"));

/*
  MarketingHeaderWithAuth
  Composes MarketingHeader and adds Auth logic and Profile Pill.

  This component will render the Logo on left, and either Log In button (with auth modal function)
  or Profile Pill on right, depending on login state.

  Additional header contents are left up to parent to implement renderAuthContent()
  or renderUnauthContent() to include additional header elements.
*/

interface Props {
  renderAuthContent?: () => React.ReactNode;
  renderUnauthContent?: () => React.ReactNode;
  onSignInEvent?: () => void;
  hideLoginButton?: boolean;
  hideBottomBorder?: boolean;
}

export const MarketingHeaderWithAuth = ({
  onSignInEvent,
  hideBottomBorder,
}: Props): React.ReactElement => {
  const router = useRouter();
  const { width } = useWindowSize();
  const { user, loadingCurrentUser } = useAuth();
  const [isCreator, setIsCreator] = useState(false);
  const { presentAuthModal } = useAllAuthModalFunctions();
  const signOut = useSignOut();
  const { onQuestionClick } = useIntercomFeature();

  const homepageLink = "/";
  const [menuActive, setMenuActive] = useState(false);
  const [showMyClasses, setShowMyClasses] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const isUserLoggedIn = !!user;
  const [showEarningsDashboard, setShowEarningsDashboard] = useState(false);
  const [showCreateClass, setShowCreateClass] = useState(false);
  // const FOR_CREATORS_TEXT = "Teach on Studio";

  useEffect(() => {
    document.body.style.overflow = menuActive ? "hidden" : "initial";
  }, [menuActive]);

  useEffect(() => {
    if (user && !loadingCurrentUser) {
      if (user.creatorInfo?.isCreator) {
        setIsCreator(true);
        setShowEarningsDashboard(user.creatorInfo?.showEarningsDashboard === true);
        setShowCreateClass(user.creatorInfo?.canCreateClasses === true);
      } else {
        setIsCreator(false);
        setShowEarningsDashboard(false);
        setShowCreateClass(false);
      }
    } else {
      setIsCreator(false);
      setShowEarningsDashboard(false);
      setShowCreateClass(false);
    }
  }, [user, loadingCurrentUser]);

  useEffect(() => {
    if (user && !loadingCurrentUser && user.creatorInfo?.isCreator) {
      isCreatorWithDrafts().then((hasDrafts) => setShowMyClasses(hasDrafts));
    }
  }, [user, loadingCurrentUser]);

  const onSignIn = React.useCallback(() => {
    onSignInEvent?.();
    handleMenuClick();
    presentAuthModal(AuthForm.Login, {
      pageSource: router.pathname,
      interactionSource: AuthModalInteractionSource.Header,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentAuthModal]);

  const onSignUp = React.useCallback(() => {
    presentAuthModal(AuthForm.CreateAccount, {
      pageSource: router.pathname,
      interactionSource: AuthModalInteractionSource.Header,
    });
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentAuthModal]);

  const onSignOut = React.useCallback(async () => {
    handleMenuClick();
    await signOut();
  }, [signOut]);

  const handleMenuClick = () => {
    setMenuActive(false);
  };

  const onContactSupportClick = () => {
    handleMenuClick();
    onQuestionClick();
  };

  const handleCreateClass = async () => {
    await createProductDraft().then((res) => {
      router.push(`/classes/create/${res.id}`);
    });
  };

  const isMobile = useMemo(() => {
    return width < 835;
  }, [width]);

  return (
    <div className={styles.headerContainer}>
      <MarketingHeader hideBottomBorder={hideBottomBorder}>
        <>
          {isMobile && (
            <MobileNavigation
              menuActive={menuActive}
              handleMenuClick={handleMenuClick}
              onSignIn={onSignIn}
              onSignUp={onSignUp}
              onSignOut={onSignOut}
              onContactSupportClick={onContactSupportClick}
              setMenuActive={setMenuActive}
            />
          )}

          <div className={styles.headerWrap}>
            {isMobile && (
              <button
                className={styles.burgerMenuButton}
                onClick={() => setMenuActive(!menuActive)}
              >
                <BurgerMenu />
              </button>
            )}

            <Link className={styles.headerLogo} href={homepageLink} passHref>
              <a className={styles.headerLogo}>
                <Logo size="medium" theme="dark" />
              </a>
            </Link>

            {isMobile && (
              <div className={styles.mobileIconsContainer}>
                <SearchIcon
                  onClick={() => {
                    setShowSearchModal(true);
                  }}
                />
                <CartPill iconSize={20} />
              </div>
            )}

            {!isMobile && (
              <DesktopNavigation
                showCreateClass={showCreateClass}
                isUserLoggedIn={isUserLoggedIn}
                isCreator={isCreator}
                showMyClasses={showMyClasses}
                showEarningsDashboard={showEarningsDashboard}
                onSignIn={onSignIn}
                onSignOut={onSignOut}
                handleCreateClass={handleCreateClass}
              />
            )}
          </div>

          {showSearchModal && (
            <OverlayModal
              hideOuterScroll
              slideUpAnimation
              isOpen={showSearchModal}
              contentClass={styles.searchModalStyles}
              onClose={() => {
                setShowSearchModal(false);
              }}
            >
              <SearchModal
                handleCloseModal={() => {
                  setShowSearchModal(false);
                }}
              />
            </OverlayModal>
          )}
        </>
      </MarketingHeader>
    </div>
  );
};
